import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import {useDataFromJson} from "../services/DataLoaderService";

// actions
import {setRoundsData} from "../redux/actions/global/setRoundsData";
import {setCodeList} from "../redux/actions/global/setCodeList";
import ModalComponent from "../components/ModalComponent";
import IntroVideo from "../assets/videos/video-intro.mp4";

const IndexPage = ({currentRound, totalRounds, dispatch}) => {
    const [videoModalOpenState] = React.useState(true);
    const {roundsData, codeList} = useDataFromJson();

    React.useEffect(() => {
        dispatch(setRoundsData(roundsData));
        dispatch(setCodeList(codeList));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        navigate("/code", {replace: true});
    };

    return (
        <DefaultLayoutComponent>
            <h1 className="display-5 stone-light px-3 mb-0">CAPAX IMPERI</h1>
            <div className="text-center px-3">
                <button className="hk-btn hk-btn-light mx-auto mb-1" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                </button>
                <p className="text-black-50 mb-0">
                    <small>Commencer</small>
                </p>
            </div>
            <ModalComponent modalId="videoModal" modalType="Video" fullscreen={true} extraData={{videoSrc: IntroVideo}} open={videoModalOpenState}/>
        </DefaultLayoutComponent>
    )
}

export default connect(state => ({
    currentRound: state.global.currentRound,
    totalRounds: state.global.totalRounds
}), null)(IndexPage)
