/**
 * DataLoaderService
 *
 * This file contains the query used to fetch all json datas from the src/data directory.
 * This query is used in the pages/index.js file (corresponding to the '/'route) to retrieve datas and save them in the redux store.
 * When you need to update a json structure, remind to keep it up to date here.
 */

import {useStaticQuery, graphql} from "gatsby"

// Query used to fetch AllJson data from the data directory
// TODO : This query must be manually maintained up to date with the attributes in json files and the game types
export const useDataFromJson = () => {
    const {allRoundsDataJson, allCodeListJson} = useStaticQuery(
        graphql`
            query AllToursAndAvailableCodes {
              allRoundsDataJson {
                nodes {
                  main {
                    date
                  }
                }
              }
              allCodeListJson {
                nodes {
                  code
                  confirmable
                  modalData {image, legend, copyright, description, artwork, confirmationText}
                  localisation
                  introType
                  introData {text, videoFilename, encouteredCharacter}
                  singleUse
                  type
                  hasIntroduction
                  formType
                  formData {texte, investmentCaracteristic, question, choices {label, type, min, max, value}}
                  validator
                  conditions {name, checks}
                }
              }
            }

        `
    );
    return {roundsData : allRoundsDataJson.nodes, codeList: allCodeListJson.nodes};
};
